/* eslint-disable no-nested-ternary */
import { GeneralErrorPanel, MainContent, SalesOrderLineItemsGrid } from '@components';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import { PipelineContainer } from '@containers/PipelineContainer';
import { getIntegrationImageSrc } from '@containers/formatters';
import { ResourceType } from '@models';
import {
  ActionBar,
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  DATE_FORMAT_NO_TIME,
  DATE_FORMAT,
  Dialog,
  DialogProps,
  DialogTypes,
  Flex,
  Icon,
  Link,
  Panel,
  Tab,
  Text,
  Tooltip,
  Widget,
  formatUtcToLocal,
  validation,
  FormValidator,
} from '@oplog/express';
import { urls } from '@routes/urls';
import {
  CargoType,
  IntegrationType,
  LineItemOfSalesOrderQueryOutputDTO,
  LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO,
  PackageOutputDTO,
  PackageOutputDTODynamicQueryOutputDTO,
  SalesOrderDetailOutputDTO,
  SalesOrderState,
  StateDetailsOutputDTO,
} from '@services';
import { checkShippingAddress, integrationIdentifier } from '@utils';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Props } from '../../atoms/Component/Component';
import CancelLineItemModal, { CancelLineItemModalProps } from '../../molecules/CancelLineItemModal/CancelLineItemModal';
import NewPackageModal, { NewPackageModalProps } from '../../molecules/NewPackageModal/NewPackageModal';
import { OrderStates, SalesOrderSerialNumbersGrid } from '../../organisms';
import UpdateCargoInfosModal, { UpdateCargoInfosModalProps } from '@components/molecules/UpdateCargoInfosModal/UpdateCargoInfosModal';

const COMPONENT_INTL_KEY = 'SalesOrderDetails';

export interface SalesOrderDetailsProps extends SalesOrderDetailOutputDTO, Props {
  cancelOrder: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
  };
  cancelPackage?: {
    isBusy: boolean;
    isCompleted: boolean;
    isError: boolean;
    getError: any;
  };
  grid?: {
    salesOrderLineItems: any;
  };
  validator: FormValidator;
  salesOrderPackages: PackageOutputDTODynamicQueryOutputDTO;
  salesOrderDetails: SalesOrderDetailOutputDTO;
  salesOrderLineItems: LineItemOfSalesOrderQueryOutputDTODynamicQueryOutputDTO;
  cargoCarriers: any;
  cancelOrderCommand: () => void;
  initResources: () => void;
  refreshData: () => void;
  initCancelPackageError: () => void;
  onTabChange: (refNumber: any, options?: { isMain?: boolean; isPackageless?: boolean }) => void;
  getLineItems: (hasPackage: boolean) => void;
  getCargoCarriers: () => void;
  createPackageRequest?: NewPackageModalProps['requestInfo'];
  cancelLineItemRequest?: CancelLineItemModalProps['requestInfo'];
  updateCargoInfoRequest?: UpdateCargoInfosModalProps['requestInfo'];
  onCreatePackage?: NewPackageModalProps['onCreate'];
  onUpdateCargoInfo?: UpdateCargoInfosModalProps['onCreate'];
  cancelPackageCommand: (refNumber: string) => void;
  cancelLineItemCommand: (sku: string, amount: number) => void;
  goTab: (tab: string) => void;
  salesOrderStateDetails: StateDetailsOutputDTO;
  getSalesOrderStateDetails: () => void;
  onFileSelect: (file: File) => void;
  isUploading: boolean;
  fileUrl: string;
  onReset: () => void;
}

interface Modals {
  cancelModal: boolean;
  packageModal: boolean;
}
export interface SalesOrderDetailsState {
  modals: Modals;
  currentTab: number;
}

enum ErrorKey {
  ArrivedToWarehouse = 'ArrivedToWarehouse',
  Source = 'Source',
}

interface GridHeaderProps {
  packageDetails: {
    title: string;
    value: string;
    isTrackingUrl?: boolean;
    isSameDayDelivery?: boolean;
    isTrackingId?: boolean;
    isExpectedShipmentDate?: boolean;
    isManualDeliveryDate?: boolean;
  }[];
  orderPackage?: PackageOutputDTO;
}

const GridHeader: React.FC<GridHeaderProps> = ({ packageDetails, orderPackage }) => {
  return (
    <Box display="flex" height="100%">
      {packageDetails.map((detail, index) => {
        if (detail.value) {
          if (
            detail.isTrackingId &&
            (orderPackage?.state == 'Created' || orderPackage?.state == 'Picked' || orderPackage?.state == 'Packed')
          ) {
            return;
          }
          if (detail.isTrackingUrl) {
            return (
              <Box
                display="flex"
                alignItems="center"
                height="full"
                borderRight="xs"
                borderColor="palette.snow"
                p="14px"
                key={index.toString()}
              >
                <Box as="span" display="inline-flex" alignItems="center" height="full" fontWeight={800}>
                  <Icon name="fas fa-truck-moving" />
                  <Box ml="4px">
                    <Link href={detail.value} isExternal={true}>
                      {detail.title}
                    </Link>
                  </Box>
                </Box>
              </Box>
            );
          }
          if (detail.isExpectedShipmentDate || detail.isManualDeliveryDate) {
            return <Box key={index.toString()}></Box>;
          }
          if (detail.isSameDayDelivery) {
            return (
              <Box
                display="flex"
                alignItems="center"
                height="full"
                borderRight="xs"
                borderColor="palette.snow"
                p="14px"
                key={index.toString()}
              >
                <Box as="span" display="inline-flex" alignItems="center" height="full" fontWeight={800}>
                  <Icon name="fas fa-exclamation-circle" />
                  <Box ml="4px">{detail.title}</Box>
                </Box>
              </Box>
            );
          }

          return (
            <Box
              display="flex"
              alignItems="center"
              height="full"
              borderRight="xs"
              borderColor="palette.snow"
              p="14px"
              key={index.toString()}
            >
              <Box as="span" display="inline-flex" alignItems="center" height="full" fontWeight={800}>
                {detail.title}
              </Box>
              <Box as="span" display="inline-flex" height="full" alignItems="center" ml="4px">
                {detail.value}
              </Box>
            </Box>
          );
        }
        return null;
      })}
    </Box>
  );
};

export const SalesOrderDetails: React.FC<SalesOrderDetailsProps> = ({
  intl,
  state,
  error,
  errorCreatePackages,
  customerFullName,
  referenceNumber,
  isBusy,
  integrationName,
  integrationType,
  cancelOrder,
  shippingAddressFullName,
  shippingAddress,
  validator,
  salesOrderPackages,
  onTabChange,
  pending,
  refreshData,
  initCancelPackageError,
  paymentOption,
  trackingUrl,
  cargoType,
  totalPaymentAmount,
  cancelOrderCommand,
  onDidMount,
  currency,
  hasPackage,
  hasPackagelessLineItems,
  getLineItems,
  getCargoCarriers,
  salesOrderLineItems,
  cargoCarriers,
  onCreatePackage,
  onUpdateCargoInfo,
  createPackageRequest,
  updateCargoInfoRequest,
  packageCreationType,
  cancelPackageCommand,
  cancelPackage,
  cancelLineItemCommand,
  cancelLineItemRequest,
  hasPackableLineItems,
  isCancellable,
  onWillUnmount,
  goTab,
  getSalesOrderStateDetails,
  salesOrderStateDetails,
  openApiIdentifier,
  onFileSelect,
  isUploading,
  fileUrl,
  onReset,
  ...otherProps
}) => {
  const [packageModal, setPackageModal] = React.useState(false);
  const [updateCargoInfoModal, setUpdateCargoInfoModal] = React.useState(false);
  const [hasNoItems, setHasNoItems] = React.useState(false);
  const [cancelLineItemModal, setCancelLineItemModal] = React.useState(false);
  const [cancelledLineItem, setCancelledLineItem] = React.useState<LineItemOfSalesOrderQueryOutputDTO>();
  const [currentTab, setCurrentTab] = React.useState(0);
  const [dialog, setDialog] = React.useState<DialogProps>();
  const [cancelLineItemDialog, setCancelLineItemDialog] = React.useState<boolean | undefined>();
  const [deletePackageRef, setDeletePackageRef] = React.useState<string>('');
  const [receivedPackageNumber, setPackageNumber] = React.useState<any>(undefined);
  const [cargoInfoUpdatable, setCargoInfoUpdatable] = React.useState<boolean | undefined>(undefined);

  let orderState: keyof typeof OrderStates = 'LineItems';
  if (hasPackagelessLineItems && hasPackage) orderState = 'CancelledItems';
  if (hasPackagelessLineItems && hasPackage && pending) orderState = 'WaitingForPackaging';

  React.useEffect(() => {
    onDidMount && onDidMount();
    return () => {
      onWillUnmount && onWillUnmount();
    };
  }, []);

  React.useEffect(() => {
    if (salesOrderLineItems !== undefined) {
      const hasNoItemFlag =
        salesOrderLineItems.data?.filter(lineItem => lineItem.amountInOrder === lineItem.missingQuantity).length ===
        salesOrderLineItems.count;
      if (hasNoItemFlag) {
        setHasNoItems(true);
      }
    }
  }, [salesOrderLineItems]);

  React.useEffect(() => {
    if (hasPackage !== undefined) {
      getLineItems(hasPackage);
    }
  }, [hasPackage]);

  React.useEffect(() => {
    if (cancelPackage?.isCompleted) {
      setDialog(undefined);
      refreshData();
    }
    if (cancelPackage?.isError) {
      setDialog({
        type: DialogTypes.Danger,
        message:
          cancelPackage?.getError?.code === 428 ? (
            <FormattedMessage
              id="SalesOrderDetails.Error.Sync"
              values={{ referenceNumber: <strong>{deletePackageRef}</strong> }}
            />
          ) : (
            intl.messages['PostErrorMessage.Description']
          ),
        isOpen: true,
        text: {
          approve: intl.messages[`Modal.Danger.Okay`],
        },
        isLoading: cancelPackage?.isBusy,
        onApprove: () => setDialog(undefined),
      });
      initCancelPackageError();
    }
  }, [cancelPackage]);

  React.useEffect(() => {
    if (pending !== undefined && pending === true) {
      getSalesOrderStateDetails();
    }
  }, [pending])

  const integrationIconName = integrationIdentifier(integrationType, openApiIdentifier);

  const isCargoInfoUpdatable = () => {
    // Check if state is either Cancelled or Delivered
    if (state === SalesOrderState.Cancelled || state === SalesOrderState.Delivered) {
      return false;
    }

    // Check if the order is a marketplace order by checking if cargoCode or cargoDocumentUrl exists.
    const isMarketplaceOrder = salesOrderStateDetails?.details?.some(detail => {
      if (detail.details) {
        const { cargoCode, cargoDocumentUrl } = detail.details as any;
        return (cargoCode && cargoCode !== '') || (cargoDocumentUrl && cargoDocumentUrl !== '');
      }
      return false;
    });

    if (isMarketplaceOrder) {
      return true;
    }

    return false;
  }

  React.useEffect(() => {
    if (!isBusy && salesOrderStateDetails && salesOrderStateDetails.details && state && cargoInfoUpdatable === undefined) {
      const isUpdatable = isCargoInfoUpdatable();
      setCargoInfoUpdatable(isUpdatable);
    }
  }, [salesOrderStateDetails, state])

  const subtitle = `${intl.messages[`${COMPONENT_INTL_KEY}.Subtitle`]}`;
  const breadcrumb = [
    { title: `${intl.messages['SalesOrders.Header.Title']}`, url: urls.salesOrders },
    { title: `#${referenceNumber}` },
  ];

  const dynamicTabs = salesOrderPackages?.data?.map((orderPackage, i) => {
    const packageDetails = [
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.RefNo`],
        value: orderPackage.referenceNumber,
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.Status`],
        value: intl.messages[`Packages.PackageState.${orderPackage.state}`],
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.CreatedAt`],
        value: formatUtcToLocal(orderPackage.createdAt),
      },
      {
        title: orderPackage.isExternalContract ? '' : intl.messages[`${COMPONENT_INTL_KEY}.Grid.TrackingId`],
        value: orderPackage.isExternalContract
          ? ''
          : Boolean(orderPackage.shippingTrackingId)
            ? orderPackage.shippingTrackingId
            : intl.messages[`${COMPONENT_INTL_KEY}.Section.Status.Details.AwaitingTrackingId`],
        isTrackingId: true,
      },
      {
        title: orderPackage.isExternalContract ? '' : intl.messages[`${COMPONENT_INTL_KEY}.Grid.TrackingUrl`],
        value: orderPackage.isExternalContract ? '' : orderPackage.trackingUrl,
        isTrackingUrl: true,
      },
      {
        title: orderPackage.isExternalContract ? '' : intl.messages[`${COMPONENT_INTL_KEY}.Grid.TotalPackageCount`],
        value: orderPackage.isExternalContract ? '' : orderPackage.totalPackageCount
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.ExpectedShipmentDate`],
        isExpectedShipmentDate: cargoType != CargoType.ScheduledDelivery,
        value: orderPackage.expectedShipmentDate ? formatUtcToLocal(orderPackage.expectedShipmentDate, DATE_FORMAT_NO_TIME) : '',
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.ManualDeliveryDate`],
        isManualDeliveryDate: cargoType != CargoType.ManualDelivery || !orderPackage.expectedShipmentDate,
        value: orderPackage.expectedShipmentDate ? formatUtcToLocal(orderPackage.expectedShipmentDate, DATE_FORMAT) : '',
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.SameDayDelivery`],
        isSameDayDelivery: cargoType === CargoType.SameDayDelivery,
        value: cargoType === CargoType.SameDayDelivery ? 'SameDayDelivery' : '',
      },
    ];

    return {
      id: orderPackage.id,
      title: (
        <Flex justifyContent="space-between">
          {`${intl.messages[`${COMPONENT_INTL_KEY}.Grid.Package`]} ${i + 1}`}
          {orderPackage.isCancellable && (
            <Button
              size="small"
              contentProps={{ paddingTop: 0 }}
              bg="transparent"
              addonProps={{ bg: 'transparent', py: 0, color: 'palette.steel_dark' }}
              _hover={{ bg: 'palette.steel_lighter' }}
              dropdown={{
                items: [
                  {
                    icon: { name: 'fal fa-cog' },
                    text: intl.messages['Packages.Cancel'],
                    value: 'cancel',
                    onClick: (a, b, e) => {
                      e.stopPropagation();
                      setDeletePackageRef(orderPackage.referenceNumber);
                      setDialog({
                        type: DialogTypes.Confirmation,
                        isOpen: true,
                        text: {
                          approve: intl.messages[`Modal.Warning.Okay`],
                          cancel: intl.messages[`Modal.Warning.Cancel`],
                        },
                        isLoading: cancelPackage?.isBusy,
                        message: (
                          <FormattedMessage
                            id={`${COMPONENT_INTL_KEY}.CancelOrderConfirmation`}
                            defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.CancelOrderConfirmation`]}
                            values={{
                              name: <b>{shippingAddressFullName}</b>,
                              no: <b>{orderPackage.referenceNumber}</b>,
                            }}
                          />
                        ),
                        onApprove: () => cancelPackageCommand(orderPackage.referenceNumber),
                        onCancel: () => setDialog(undefined),
                      });
                    },
                  },
                ],
                placement: 'bottom',
                popoverProps: {
                  onClick: e => e.stopPropagation(),
                  closeOutside: true,
                  withArrow: true,
                  withPortal: true,
                },
              }}
            />
          )}
        </Flex>
      ),
      component: (
        <SalesOrderLineItemsGrid
          intl={intl}
          isPending={pending}
          referenceNumber={orderPackage.referenceNumber}
          titleKey={orderPackage.referenceNumber}
          headerContent={<GridHeader packageDetails={packageDetails} orderPackage={orderPackage} />}
          isPackage
        />
      ),
    };
  });

  const cancelStatus = (): { isDisabled: boolean; key: string | undefined } => {
    const states = [
      SalesOrderState.ReadyToBeShipped,
      SalesOrderState.Shipped,
      SalesOrderState.Delivered,
      SalesOrderState.Cancelled,
    ];
    const stateDisabled = states.indexOf(state) > -1;
    let key;
    if (stateDisabled && state !== SalesOrderState.Cancelled) {
      key = ErrorKey.ArrivedToWarehouse;
    }
    return {
      isDisabled: stateDisabled,
      key,
    };
  };

  const initResources = () => {
    setDialog(undefined);
    setCancelLineItemModal(false);
    setCancelLineItemDialog(undefined);
    otherProps.initResources();
  };

  const onSuccessClose = () => {
    initResources();
    refreshData();
  };

  const cancelInfo = cancelStatus();

  if (history.state && history.state.state && receivedPackageNumber == undefined) {
    setPackageNumber(history.state.state.packageNumber - 1);
    setCurrentTab(history.state.state.packageNumber - 1);
  }

  if (hasPackage && dynamicTabs) {
    const packageDetails = [
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.RefNo`],
        value: referenceNumber,
      },
      {
        title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.Status`],
        value: intl.messages[`Packages.PackageState.${state}`],
      },
    ];

    dynamicTabs.push({
      id: 'serialNumbers',
      title: (
        <Flex justifyContent="space-between">{`${intl.messages[`${COMPONENT_INTL_KEY}.Grid.SerialNumbers`]}`}</Flex>
      ),
      component: (
        <SalesOrderSerialNumbersGrid
          intl={intl}
          titleKey={referenceNumber}
          referenceNumber={referenceNumber}
          headerContent={<GridHeader packageDetails={packageDetails} />}
          isPending={false}
        />
      ),
    });
  }

  return (
    <MemberPageContainer id="sales-order-detail" documentTitle={`#${referenceNumber} - ${subtitle}`}>
      <ActionBar
        top="66px"
        title={referenceNumber ? `#${referenceNumber}` : ''}
        subtitle={referenceNumber ? subtitle : intl.messages['ErrorPanel.ErrorMessage']}
        isLoading={isBusy}
        integration={{
          icon: getIntegrationImageSrc(integrationIconName),
          name: integrationName || intl.messages['Integration.NoIntegration'],
        }}
        breadcrumb={breadcrumb}
      >
        <Flex marginLeft="auto">
          {pending && (
            <Tooltip
              placement="bottom-end"
              content={
                packageCreationType === 'ByWholeLineItems' ? (
                  <Box maxWidth="200px">{intl.messages['Packages.IntegrationType']}</Box>
                ) : !hasPackableLineItems ? (
                  <Box maxWidth="200px">{intl.messages['Packages.NoPackableLineItems']}</Box>
                ) : (
                  undefined
                )
              }
            >
              <Button // Create a new package.
                disabled={!hasPackableLineItems || packageCreationType === 'ByWholeLineItems' || hasNoItems}
                size="large"
                onClick={() => setPackageModal(true)}
                setCancelLineItemModal
                mr={11}
              >
                {intl.messages['Packages.Create']}
              </Button>
            </Tooltip>
          )}

          <Tooltip
            placement="bottom-end"
            content={
              cancelInfo.isDisabled &&
              cancelInfo.key &&
              state !== SalesOrderState.Cancelled && (
                <Box maxWidth="200px">{intl.messages[`${COMPONENT_INTL_KEY}.Warning.${cancelInfo.key}`]}</Box>
              )
            }
          >
            <Flex marginLeft="auto">

              {cargoInfoUpdatable && <Button mr="6" size="large" variant="info"
                onClick={() => setUpdateCargoInfoModal(true)}>
                {intl.messages[`${COMPONENT_INTL_KEY}.UpdateCargoInfos`]}
              </Button>}

              <Button
                marginLeft="auto"
                variant="danger"
                size="large"
                onClick={() => {
                  if (integrationType == IntegrationType.Oplog) {
                    setDialog({
                      type: DialogTypes.Danger,
                      isOpen: !cancelOrder.isError,
                      isLoading: cancelOrder.isBusy,
                      text: {
                        approve: intl.messages[`Modal.Warning.Okay`],
                        cancel: intl.messages[`Modal.Warning.Cancel`],
                      },
                      onApprove: () => {
                        cancelOrderCommand();
                        setDialog(undefined);
                      },
                      onCancel: () => setDialog(undefined),
                      message: (
                        <FormattedMessage
                          id={`${COMPONENT_INTL_KEY}.CancelOrderConfirmation`}
                          defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.CancelOrderConfirmation`]}
                          values={{
                            name: <b>{shippingAddressFullName}</b>,
                            no: <b>#{referenceNumber}</b>,
                          }}
                        />
                      ),
                    });
                  } else {
                    setDialog({
                      type: DialogTypes.Warning,
                      isOpen: !cancelOrder.isError,
                      isLoading: cancelOrder.isBusy,
                      text: {
                        approve: intl.messages[`Modal.Warning.Okay`],
                        cancel: intl.messages[`Modal.Warning.Cancel`],
                      },
                      onApprove: () => {
                        cancelOrderCommand();
                        setDialog(undefined);
                      },
                      onCancel: () => setDialog(undefined),
                      message: (
                        <FormattedMessage
                          id={`${COMPONENT_INTL_KEY}.CancelOrderConfirmationNonEntegration`}
                          defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.CancelOrderConfirmationNonEntegration`]}
                          values={{
                            name: <b>{shippingAddressFullName}</b>,
                            no: <b>#{referenceNumber}</b>,
                          }}
                        />
                      ),
                    });
                  }
                }}
                disabled={cancelInfo.isDisabled || !isCancellable}
              >
                {intl.messages[`${COMPONENT_INTL_KEY}.CancelOrder`]}
              </Button>
            </Flex>
          </Tooltip>
        </Flex>
      </ActionBar>
      {(cancelOrder.isError || cancelPackage?.isError) && (
        <Alert variant="danger" isOpen={cancelOrder.isError} onDismiss={() => initResources()}>
          <AlertTitle>{intl.messages['PostErrorMessage.Title']}</AlertTitle>
          <AlertDescription>{intl.messages['PostErrorMessage.DefaultMessage']}</AlertDescription>
        </Alert>
      )}
      <MainContent>
        <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Section.Status.Title`]}>
          {pending !== undefined ? (
            pending ? (
              <Box
                border="xs"
                borderColor="palette.orange_dark"
                borderRadius="sm"
                backgroundColor="palette.orange"
                p="16"
              >
                <Icon mr="12px" fontSize="21px" color="palette.white" name="fas fa-exclamation-triangle" />
                <Text color="palette.white" fontFamily="heading" fontSize="12" fontWeight="600">
                  {intl.messages[`${COMPONENT_INTL_KEY}.Warning.NoStockOfProducts`]}
                </Text>
              </Box>
            ) : (
              <PipelineContainer
                pipelineKey={ResourceType.PipelineStateOfSalesOrder}
                apiParam={{ referenceNumber }}
                intl={intl}
                enumIntlKey="OrderState"
                detailsIntlKey={`${COMPONENT_INTL_KEY}.Section.Status.Details`}
                salesOrderPackages={salesOrderPackages}
              />
            )
          ) : (<Skeleton count={3} />)}
        </Panel>

        <Box pl="0" pt="22">
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.Title`]}>
            {error ? (
              <GeneralErrorPanel className="align-left" />
            ) : (
              <Flex flexDirection="row" flexWrap="wrap">
                <Box width={[1, 1, 1, 1 / 4]} pr={['0', '0', '30']} mb={['22', '22', '22', '0']}>
                  <Widget.Two
                    title={customerFullName}
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.CustomerFirstNameLastName`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-address-book" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.orange' }}
                  />
                </Box>
                <Box width={[1, 1, 1, 2 / 4]} pr={['0', '0', '0', '30']} mb={['22', '22', '22', '0']}>
                  <Widget.Two
                    title={
                      <div>
                        <span style={{ fontWeight: 'bold' }}>{shippingAddressFullName} - </span>
                        <span style={{ fontWeight: 'normal' }}>{checkShippingAddress(shippingAddress)}</span>
                      </div>
                    }
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.DeliveryInfo`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-truck" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.green' }}
                  />
                </Box>
                <Box width={[1, 1, 1, 1 / 4]} pr="0" mb="0">
                  <Widget.Two
                    title={
                      <div>
                        <span style={{ fontWeight: 'bold' }}>
                          {
                            intl.messages[
                            `${COMPONENT_INTL_KEY}.Section.Info.OrderInfoFormPaymentOptions.${paymentOption}`
                            ]
                          }
                          <br />
                        </span>
                        <span style={{ fontWeight: 'normal' }}>
                          {totalPaymentAmount}{' '}
                          {intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.Currencies.${currency}`]}
                        </span>
                      </div>
                    }
                    subtitle={intl.messages[`${COMPONENT_INTL_KEY}.Section.Info.PaymentInfo`]}
                    loading={isBusy}
                    icon={<Icon color="white" name="fas fa-money-bill" fontSize="56px" />}
                    iconContainerProps={{ bg: 'palette.blue' }}
                  />
                </Box>
              </Flex>
            )}
          </Panel>
        </Box>
        <Box mt="22">
          {salesOrderLineItems ? (
            <Tab
              onTabChange={tabIndex => {
                setCurrentTab(tabIndex);
                const prevTabIndex = currentTab;
                const packagesCount = salesOrderPackages?.data?.length;
                if (salesOrderLineItems.count === 0) {
                  if (packagesCount && tabIndex !== packagesCount && prevTabIndex !== packagesCount) {
                    onTabChange(salesOrderPackages?.data?.[tabIndex].referenceNumber);
                  }
                } else if (tabIndex !== 0) {
                  if (packagesCount && tabIndex <= packagesCount && prevTabIndex - 1 !== packagesCount) {
                    onTabChange(salesOrderPackages?.data?.[tabIndex - 1].referenceNumber);
                  }
                } else if (prevTabIndex - 1 !== packagesCount) {
                  onTabChange(referenceNumber, {
                    isMain: true,
                    isPackageless: hasPackagelessLineItems,
                  });
                }
              }}
              activeIndex={currentTab}
              tabs={[
                ...(hasPackage === false || salesOrderLineItems.count > 0
                  ? [
                    {
                      id: 0,
                      title: intl.messages[`${COMPONENT_INTL_KEY}.Grid.${orderState}`],
                      component: (
                        <SalesOrderLineItemsGrid
                          intl={intl}
                          isPending={pending}
                          referenceNumber={referenceNumber}
                          packageless={hasPackagelessLineItems}
                          titleKey={referenceNumber}
                          items={salesOrderLineItems}
                          state={orderState}
                          onCancelLineItem={rowData => {
                            if (rowData.cancellableAmount > 1) {
                              setCancelLineItemModal(true);
                            } else {
                              setCancelLineItemDialog(true);
                            }
                            setCancelledLineItem(rowData);
                          }}
                        />
                      ),
                    },
                  ]
                  : []),
                ...(hasPackage && dynamicTabs ? dynamicTabs : []),
              ]}
            />
          ) : (
            <Box>
              <Skeleton count={10} />
            </Box>
          )}
        </Box>
        {packageModal && (
          <NewPackageModal
            referenceNumber={referenceNumber}
            items={salesOrderLineItems}
            cargoType={cargoType}
            onClose={() => {
              setPackageModal(false);
              if (createPackageRequest?.isCompleted) {
                onSuccessClose();
              }
            }}
            onCreate={(data) => {
              const newData = {
                salesOrderReferenceNumber: referenceNumber,
                lineItems: data.selectedItems,
                expectedShipmentDate: data.expectedShipmentDate
              };
              onCreatePackage && onCreatePackage(newData); 
            }}
            type={packageCreationType}
            requestInfo={createPackageRequest}
            error={errorCreatePackages}
          />
        )}
        {updateCargoInfoModal && (
          <UpdateCargoInfosModal
            validator={validator}
            referenceNumber={referenceNumber}
            onClose={() => {
              setUpdateCargoInfoModal(false);
              if (updateCargoInfoRequest?.isCompleted) {
                onSuccessClose();
              }
            }}
            onCreate={data => {
              onUpdateCargoInfo && onUpdateCargoInfo(data);
            }}
            requestInfo={updateCargoInfoRequest}
            cargoCarriers={cargoCarriers}
            onFileSelect={onFileSelect}
            fileUrl={fileUrl}
            isUploading={isUploading}
            onFileReset={onReset}
          />
        )}
        {(cancelLineItemModal || cancelLineItemDialog) && (
          <CancelLineItemModal
            item={cancelledLineItem!}
            onClose={() => {
              setCancelLineItemModal(false);
              setCancelLineItemDialog(false);

              if (cancelLineItemRequest?.isCompleted) {
                onSuccessClose();
              }
            }}
            onDialogClose={() => setCancelLineItemDialog(undefined)}
            onCancel={(sku, amount) => cancelLineItemCommand(sku, amount)}
            requestInfo={cancelLineItemRequest}
            dialogOpen={cancelLineItemDialog}
          />
        )}
        <Dialog
          type={dialog?.type || DialogTypes.Success}
          isOpen={dialog?.isOpen || (cancelOrder.isCompleted && !cancelOrder.isError)}
          isLoading={dialog?.isLoading || cancelOrder.isBusy || cancelPackage?.isBusy}
          text={{
            approve: dialog?.text?.approve || intl.messages[`Modal.Success.Okay`],
            cancel: dialog?.text?.cancel,
          }}
          onApprove={dialog?.onApprove || onSuccessClose}
          onCancel={dialog?.onCancel}
          message={
            dialog?.message || (
              <FormattedMessage
                id={`${COMPONENT_INTL_KEY}.CancelOrderSuccess`}
                defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.CancelOrderSuccess`]}
              />
            )
          }
        />
      </MainContent>
    </MemberPageContainer>
  );
};
