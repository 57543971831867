import { GeneralErrorPanel } from '@components';
import { IntegrationInfoPanel } from '@components/molecules/IntegrationInfoPanel/IntegrationInfoPanel';
import { getIntegrationImageSrc } from '@containers/formatters';
import { IntegrationChannelContainer } from '@containers/IntegrationChannelContainer/IntegrationChannelContainer';
import { MemberPageContainer } from '@containers/MemberPageContainer/MemberPageContainer';
import {
  ActionBar,
  Box,
  Button,
  Divider,
  Flex,
  FormValidator,
  LayoutContent,
  Panel,
  PanelTitle,
  Widget,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import {
  CreateIntegrationCommand,
  DeleteIntegrationCommand,
  IntegrationOutputDTO,
  IntegrationType,
  OpenApiIdentifier,
  UpdateIntegrationCommand,
} from '@services';
import useCommonDataStore from '@store/commonData/commonDataState';
import * as React from 'react';
import { Props } from '../../atoms/Component/Component';

export interface IntegrationsProps extends Props {
  integrationState: {
    isBusy?: boolean;
    error?: ErrorModel;
  };
  updateIntegrationResource: Resource<Response>;
  deleteIntegrationResource: Resource<Response>;
  validator: FormValidator;
  onUpdateIntegration: (integration: UpdateIntegrationCommand) => void;
  onUpdateIntegrationSuccess: () => void;
  onDeleteIntegration: (command: DeleteIntegrationCommand) => void;
  onDeleteIntegrationSuccess: () => void;
  initResources: () => void;
  onSyncIntegration: (integrationId: string) => void;
  onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => void;
  getIntegrationPayloadResource: Resource<Response>;
  isUpdateIntegrationsSuccessful: boolean;
  isDeleteIntegrationSuccessful: boolean;
  createIntegrationError?: ErrorModel;
  feedbackModalOpen: (subject?: string, body?: string) => void;
  cargoCarriersOutput: any;
  getCargoCarriers: () => void;
}

const COMPONENT_INTL_KEY = 'Integrations';
export type IntegrationStates = UpdateIntegrationCommand & CreateIntegrationCommand;

export const Integrations: React.FC<IntegrationsProps> = ({
  isDeleteIntegrationSuccessful,
  isUpdateIntegrationsSuccessful,
  onDeleteIntegrationSuccess,
  onUpdateIntegrationSuccess,
  intl,
  feedbackModalOpen,
  onUpdateIntegration,
  onDeleteIntegration,
  onGetIntegrationPayload,
  onSyncIntegration,
  validator,
  updateIntegrationResource,
  deleteIntegrationResource,
  initResources,
  onDidMount,
  onWillUnmount,
  integrationState,
  cargoCarriersOutput,
  getCargoCarriers,
  getIntegrationPayloadResource,
}) => {
  const breadcrumb = [{ title: intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`] }];

  const [{ integrations, cargoCarriers }, { setCargoCarriers }] = useCommonDataStore();

  React.useEffect(() => {
    onDidMount && onDidMount();
    if (cargoCarriers === undefined) {
      getCargoCarriers();
    }
    return () => onWillUnmount && onWillUnmount();
  }, []);

  React.useEffect(() => {
    setCargoCarriers(cargoCarriersOutput);
  }, [cargoCarriersOutput]);

  React.useEffect(() => {
    if (isDeleteIntegrationSuccessful) {
      onDeleteIntegrationSuccess();
      return;
    }

    if (isUpdateIntegrationsSuccessful) {
      onUpdateIntegrationSuccess();
      return;
    }
  }, [isDeleteIntegrationSuccessful, isUpdateIntegrationsSuccessful]);

  const onFeedbackModalOpen = () => {
    const subject = intl.messages[`${COMPONENT_INTL_KEY}.Feedback.Subject`];
    feedbackModalOpen(subject);
  };

  const renderMyIntegrations = () => {
    if (integrationState.error) {
      return <GeneralErrorPanel />;
    }

    if (integrationState.isBusy || integrations === undefined) {
      return new Array(4).fill('').map((item, index) => (
        <Box width={[1, 1, 1 / 2, 1 / 4]} key={`placeholder_${index.toString()}`} pr="22" pb="22">
          <Widget.Four
            key={index}
            content={[
              { icon: 'icon', text: 'text', key: 'deneme' },
              { icon: 'icon', text: 'text', key: 'deneme2' },
            ]}
            isLoading={integrationState.isBusy}
          />
        </Box>
      ));
    }

    return integrations
      .sort((a: any) => (a.type === IntegrationType.Oplog ? -1 : 0))
      .map((integration: IntegrationOutputDTO, index: number) => {
        const openApiIdentifier = integration.openApiIdentifier
        return (
          <Box width={[1, 1, 1 / 2, 1 / 4]} key={`${integration.name}_${index.toString()}`} pr="22" pb="22">
            <IntegrationInfoPanel
              intl={intl}
              integration={integration}
              validator={validator}
              onUpdateIntegration={onUpdateIntegration}
              onDeleteIntegration={onDeleteIntegration}
              integrationLogoSource={getIntegrationImageSrc(openApiIdentifier !== OpenApiIdentifier.None ? openApiIdentifier : integration.type)}
              onSyncIntegration={onSyncIntegration}
              onGetIntegrationPayload={onGetIntegrationPayload}
              getIntegrationPayloadResource={getIntegrationPayloadResource}
              isBusy={updateIntegrationResource.isBusy}
              error={updateIntegrationResource.error}
              deleteIntegrationResource={deleteIntegrationResource}
              initResources={initResources}
            />
          </Box>
        );
      });
  };

  const integrationImageFolderPath = '/images/integrations';

  const integrationsList = {
    OpenApi: {
      name: IntegrationType.OpenApi.toString(),
      image: IntegrationType.OpenApi,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.None
    },
    Shopify: {
      name: IntegrationType.Shopify.toString(),
      image: IntegrationType.Shopify,
      type: IntegrationType.Shopify,
      openApiIdentifier: OpenApiIdentifier.None
    },
    WooCommerce: {
      name: IntegrationType.WooCommerce.toString(),
      image: IntegrationType.WooCommerce,
      type: IntegrationType.WooCommerce,
      openApiIdentifier: OpenApiIdentifier.None
    },
    Ticimax: {
      name: IntegrationType.Ticimax.toString(),
      image: IntegrationType.Ticimax,
      type: IntegrationType.Ticimax,
      openApiIdentifier: OpenApiIdentifier.None
    },
    TSoft: {
      name: IntegrationType.TSoft.toString(),
      image: IntegrationType.TSoft,
      type: IntegrationType.TSoft,
      openApiIdentifier: OpenApiIdentifier.None
    },
    Entegra: {
      name: IntegrationType.Entegra.toString(),
      image: IntegrationType.Entegra,
      type: IntegrationType.Entegra,
      openApiIdentifier: OpenApiIdentifier.None
    },
    Trendyol: {
      name: IntegrationType.Trendyol.toString(),
      image: IntegrationType.Trendyol,
      type: IntegrationType.Trendyol,
      openApiIdentifier: OpenApiIdentifier.None
    },
    TrendyolGermany: {
      name: IntegrationType.TrendyolDE.toString(),
      image: IntegrationType.Trendyol,
      type: IntegrationType.Trendyol, 
      openApiIdentifier: OpenApiIdentifier.None
    },
    OrderDesk: {
      name: IntegrationType.OrderDesk.toString(),
      image: IntegrationType.OrderDesk,
      type: IntegrationType.OrderDesk, 
      openApiIdentifier: OpenApiIdentifier.None
    },
    Ikas: {
      name: 'Ikas',
      image: OpenApiIdentifier.Ikas,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.Ikas
    },
    Pixa: {
      name: 'Pixa',
      image: OpenApiIdentifier.Pixa,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.Pixa
    },
    Shopiverse: {
      name: 'Shopiverse',
      image: OpenApiIdentifier.Shopiverse,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.Shopiverse
    },
    Logo: {
      name: 'Logo',
      image: OpenApiIdentifier.Logo,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.Logo
    },
    MNM: {
      name: 'MNM',
      image: OpenApiIdentifier.MNM,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.MNM
    },
    Dopigo: {
      name: 'Dopigo',
      image: OpenApiIdentifier.Dopigo,
      type: IntegrationType.OpenApi,
      openApiIdentifier: OpenApiIdentifier.Dopigo
    }
  }

  const renderIntegrationList = () => {
    if (integrationState.error) {
      return <GeneralErrorPanel className="align-top" />;
    }
    if (integrationState.isBusy || integrations === undefined) {
      return new Array(4).fill('').map((item, index) => (
        <Box width={[1, 1 / 2, 1 / 2, 1 / 4]} key={`channel_${index.toString()}`} pr={[0, 6, 6, 6]} pb="22">
          <Widget.Five title="" button="" isLoading={integrationState.isBusy} />
        </Box>
      ));
    }

    let excludedIntegrationTypes = [
      IntegrationType.Oplog,
      IntegrationType.Custom,
      IntegrationType.CudExcludedIntegrations,
      IntegrationType.SyncExcludedIntegrations,
      IntegrationType.FulfillmentNotificationExcludedIntegrations,
      IntegrationType.FulfillmentNotificationViaPackage,
      IntegrationType.CreatePackageByQuantity,
      IntegrationType.Default,
      IntegrationType.InventoryNotificationExcludedIntegrations,
    ];

    // If integrations can added only once then add here
    const onlyOnceIntegrationTypes = [] as any;

    integrations.forEach((integration: IntegrationOutputDTO) => {
      const isExists = onlyOnceIntegrationTypes.indexOf(integration.type) > -1;
      if (isExists) {
        excludedIntegrationTypes = [...excludedIntegrationTypes, integration.type];
      }
    });

    return Object.values(integrationsList).map((integration, index) => {
      const integrationType = integration.type;

      if (excludedIntegrationTypes.some(x => x === integrationType)) {
        return null;
      }

      const integrationChannelProps = {
        type: integrationType as IntegrationType,
        title: integration.name,
        integrationLogoSource: getIntegrationImageSrc(integration.image),
        openApiIdentifier: integration.openApiIdentifier as OpenApiIdentifier,
      };
      return (
        <Box width={[1, 1, 1 / 2, 1 / 4]} key={`${integrationType}_real_${index.toString()}`} pr={[0, '22']} pb="22">
          <IntegrationChannelContainer {...integrationChannelProps} />
        </Box>
      );
    });
  };

  return (
    <MemberPageContainer documentTitle={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]}>
      <ActionBar top="66px" title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Title`]} breadcrumb={breadcrumb} />
      <LayoutContent>
        <Box width={1}>
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Panel.MyIntegrations`]}>
            <Flex flexWrap="wrap">{renderMyIntegrations()}</Flex>
          </Panel>
        </Box>
        <Box width={1}>
          <Panel title={intl.messages[`${COMPONENT_INTL_KEY}.Header.Panel.IntegrationList`]}>
            <Flex flexWrap="wrap">{renderIntegrationList()}</Flex>
          </Panel>
        </Box>
        <Divider borderColor="palette.steel" my="29px" />
        <Flex width="full" flexDirection="column" alignItems="center">
          <PanelTitle>{intl.messages[`${COMPONENT_INTL_KEY}.CannotFindIntegration`]}</PanelTitle>
          <Button variant="dark" kind="outline" onClick={onFeedbackModalOpen} size="small">
            {intl.messages[`${COMPONENT_INTL_KEY}.RequestNewIntegration`]}
          </Button>
        </Flex>
      </LayoutContent>
    </MemberPageContainer>
  );
};
