import { ShopifyIntegrationForm } from '@components/molecules/ShopifyIntegrationForm/ShopifyIntegrationForm';
import { TicimaxIntegrationForm } from '@components/molecules/TicimaxIntegrationForm/TicimaxIntegrationForm';
import { TSoftIntegrationForm } from '@components/molecules/TSoftIntegrationForm/TSoftIntegrationForm';
import { WooCommerceIntegrationForm } from '@components/molecules/WooCommerceIntegrationForm/WooCommerceIntegrationForm';
//import { IntegrationSteps } from '@components/pages/Integrations';
import {
  Box,
  Dialog,
  DialogTypes,
  Flex,
  FormValidator,
  Icon,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Text,
  Widget,
} from '@oplog/express';
import { Resource } from '@oplog/resource-redux';
import { urls } from '@routes/urls';
import { CreateIntegrationCommand, IntegrationOutputDTO, IntegrationType, OpenApiIdentifier, UpdateIntegrationCommand } from '@services';
import useIntegrationsState, {
  IntegrationKeys,
  IntegrationState,
  IntegrationSteps,
} from '@store/integrations/integrationsState';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Props } from '../../atoms/Component/Component';
import { EntegraIntegrationForm } from '../EntegraIntegrationForm/EntegraIntegrationForm';
import TicimaxMappingForm from '../IntegrationInfoPanel/bones/TicimaxMappingForm';
import TSoftMappingForm from '../IntegrationInfoPanel/bones/TSoftMappingForm';
import { OpenApiIntegrationForm } from '../OpenApiIntegrationForm/OpenApiIntegrationForm';
import { DOPIGO_INFORMATION_URL, IKAS_INFORMATION_URL, LOGO_INFORMATION_URL, MNM_INFORMATION_URL, PIXA_INFORMATION_URL, SHOPIVERSE_INFORMATION_URL } from '@utils';
import { LanguageHelper } from '../../../utils/language';
import { TrendyolIntegrationForm } from '../TrendyolIntegrationForm/TrendyolIntegrationForm';
import { OrderDeskIntegrationForm } from '../OrderDeskIntegrationForm/OrderDeskIntegrationForm';

export interface IntegrationChannelProps extends Props {
  type: IntegrationType;
  title: string;
  integrationLogoSource: string;
  validator: FormValidator;
  onCreateIntegration: (command: CreateIntegrationCommand) => void;
  handleUpdateSubmit: (command: UpdateIntegrationCommand) => void;
  refreshIntegrations: () => void;
  initResources: () => void;
  isCreateIntegrationSuccessful: boolean;
  isUpdateSuccessful: boolean;
  createIntegrationResource: any;
  integrations: any;
  createIntegrationResponseID: any;
  getIntegrationPayloadResource: Resource<Response>;
  onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => void;
  openApiIdentifier: OpenApiIdentifier;
}

const COMPONENT_INTL_KEY = 'Integrations.IntegrationChannel';

const currentLanguage = LanguageHelper.getLanguage().split('-')[0];

// After we update the outline documentation, link will be updated.
const helpUrls: Dictionary<string> = {
  // [`${IntegrationType.Shopify}`]: `${urls.helpHome}/integration-actions/add-new-integration/shopify`,
  [`${OpenApiIdentifier.Ikas}`]: `${IKAS_INFORMATION_URL}`,
  [`${OpenApiIdentifier.Pixa}`]: `${PIXA_INFORMATION_URL}`,
  [`${OpenApiIdentifier.Shopiverse}`]: `${SHOPIVERSE_INFORMATION_URL}`,
  [`${OpenApiIdentifier.Logo}`]: `${LOGO_INFORMATION_URL}${currentLanguage === 'en' ? 'en' : ''}`,
  [`${OpenApiIdentifier.MNM}`]: `${MNM_INFORMATION_URL}`,
  [`${OpenApiIdentifier.Dopigo}`]: `${DOPIGO_INFORMATION_URL}`,
};

export const IntegrationChannel: React.FC<IntegrationChannelProps> = ({
  initResources,
  createIntegrationResource,
  validator,
  onCreateIntegration,
  handleUpdateSubmit,
  refreshIntegrations,
  onWillUnmount,
  isCreateIntegrationSuccessful,
  type,
  title,
  integrations,
  intl,
  integrationLogoSource,
  isUpdateSuccessful,
  getIntegrationPayloadResource,
  onGetIntegrationPayload,
  openApiIdentifier,
}: IntegrationChannelProps) => {
  const [state, actions] = useIntegrationsState();

  const [isFormOpen, setIsFormOpen] = React.useState(false);
  const [response, setResponse] = React.useState({
    id: '',
    integrationCredentials: {},
  });

  const getHelpLink = (type: IntegrationType) => {
    if (helpUrls[type] !== undefined) {
      return helpUrls[type];
    }
    return '#';
  };

  const redirectUser = (event: any) => {
    event.preventDefault();
    window.open(helpUrls[openApiIdentifier], '_blank');
  }

  const handleClose = () => {
    if (createIntegrationResource && createIntegrationResource.isBusy) {
      return;
    }
    initResources();
    validator.clearErrors();
    setIsFormOpen(false);
    refreshIntegrations();
    window.location.reload();
  };

  const handleTsoftFormClose = () => {
    actions.setTsoftData(IntegrationKeys.Step, IntegrationSteps.Integration);
  };

  const handleTicimaxFormClose = () => {
    actions.setTicimaxData(IntegrationKeys.Step, IntegrationSteps.Integration);
  };

  React.useEffect(() => {
    if (isUpdateSuccessful) {
      actions.setStateData(IntegrationState.CreationSuccess, true);
      actions.clearTsoftData();
      actions.clearTicimaxData();
    }
  }, [isUpdateSuccessful]);

  const handleSubmit = (createIntegrationCommand: CreateIntegrationCommand) => {
    actions.setStateData(IntegrationState.CreationType, type);
    onCreateIntegration(createIntegrationCommand);
  };

  React.useEffect(() => {
    return () => {
      onWillUnmount && onWillUnmount;
      // actions.clearTsoftData();
    };
  }, []);

  React.useEffect(() => {
    // Allah affetsin burayı.
    if (isCreateIntegrationSuccessful) {
      if (
        createIntegrationResource &&
        Object.keys(createIntegrationResource.data || {}).length > 0 &&
        state.creationType == IntegrationType.OpenApi
      ) {
        setResponse(createIntegrationResource.data);
      } else if (
        createIntegrationResource &&
        Object.keys(createIntegrationResource.data || {}).length > 0 &&
        state.creationType == IntegrationType.TSoft
      ) {
        actions.setTsoftData(IntegrationKeys.Id, createIntegrationResource.data.id);
        setIsFormOpen(false);
        refreshIntegrations();
      } else if (
        createIntegrationResource &&
        Object.keys(createIntegrationResource.data || {}).length > 0 &&
        state.creationType == IntegrationType.Ticimax
      ) {
        actions.setTicimaxData(IntegrationKeys.Id, createIntegrationResource.data.id);
        setIsFormOpen(false);
        refreshIntegrations();
      } else {
        setIsFormOpen(false);
        window.location.reload();
      }
    }
  }, [isCreateIntegrationSuccessful]);

  React.useEffect(() => {
    if (state.tSoft.id !== '') {
      const integration = integrations.find((x: any) => x.id === state.tSoft.id);
      actions.setTsoftData(IntegrationKeys.Integration, integration);
      actions.setTsoftData(IntegrationKeys.Step, IntegrationSteps.Configuration);
    } else if (state.ticimax.id !== '') {
      const integration = integrations.find((x: any) => x.id === state.ticimax.id);
      actions.setTicimaxData(IntegrationKeys.Integration, integration);
      actions.setTicimaxData(IntegrationKeys.Step, IntegrationSteps.Configuration);
    }
  }, [integrations]);

  const helpLink: string = getHelpLink(type);
  const isReferenceNumberRequired = Array.isArray(integrations)
    ? !!integrations.find((integration: IntegrationOutputDTO) => integration.type === type)
    : false;

  const IntegrationFormProps = {
    validator,
    type,
    onClose: handleClose,
    onSubmit: handleSubmit,
    isEdit: false,
    isNew: true,
    intl,
    isBusy: createIntegrationResource && createIntegrationResource.isBusy,
    error: createIntegrationResource && createIntegrationResource.error,
    response,
    isReferenceNumberRequired,
  };

  const tsoftMappingFormProps = {
    intl,
    integration: state.tSoft.integration,
    onSubmit: handleUpdateSubmit,
    onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => {
      onGetIntegrationPayload(integrationId, integrationType);
    },
    getIntegrationPayloadResource,
    editMode: false,
  };

  const ticimaxMappingFormProps = {
    intl,
    integration: state.ticimax.integration,
    onSubmit: handleUpdateSubmit,
    onGetIntegrationPayload: (integrationId: string, integrationType: IntegrationType) => {
      onGetIntegrationPayload(integrationId, integrationType);
    },
    getIntegrationPayloadResource,
    editMode: false,
  };

  return (
    <Box boxShadow="medium" width={1}>
      <Widget.Five
        key={type}
        title={title}
        button={intl.messages[`${COMPONENT_INTL_KEY}.${openApiIdentifier !== OpenApiIdentifier.None ? 'GetInformation' : 'Activate'}`]}
        onClick={(e: MouseEvent) => {
          if (openApiIdentifier && openApiIdentifier !== OpenApiIdentifier.None) {
            redirectUser(e);
          }
          else {
            setIsFormOpen(true);
          }
        }}
        image={integrationLogoSource}
      />
      <Modal isOpen={isFormOpen} onClose={handleClose} size="2xl">
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Title.${type}`]}</ModalTitle>
          {type === IntegrationType.TSoft && (
            <Box mt="10px">
              <Text color={state.tSoft.step == IntegrationSteps.Integration ? 'palette.blue_link' : ''}>
                1- {intl.messages[`${COMPONENT_INTL_KEY}.Integration`]}
              </Text>{' '}
              <Text color={state.tSoft.step == IntegrationSteps.Configuration ? 'palette.blue_link' : ''} ml="10px">
                2- {intl.messages[`${COMPONENT_INTL_KEY}.Configuration`]}
              </Text>
            </Box>
          )}
          {type === IntegrationType.Ticimax && (
            <Box mt="10px">
              <Text color={state.ticimax.step == IntegrationSteps.Integration ? 'palette.blue_link' : ''}>
                1- {intl.messages[`${COMPONENT_INTL_KEY}.Integration`]}
              </Text>{' '}
              <Text color={state.ticimax.step == IntegrationSteps.Configuration ? 'palette.blue_link' : ''} ml="10px">
                2- {intl.messages[`${COMPONENT_INTL_KEY}.Configuration`]}
              </Text>
            </Box>
          )}
        </ModalHeader>
        {helpLink !== '#' && (
          <Flex alignItems="center" p="16px 22px" borderBottom="xs" borderColor="palette.snow_light">
            <Icon name="far fa-info-circle" fontSize="20px" mr="8px" />
            <FormattedMessage
              id={`${COMPONENT_INTL_KEY}.Form.Description`}
              defaultMessage={intl.messages[`${COMPONENT_INTL_KEY}.Form.Description`]}
              values={{
                link: <Link to={helpLink}>{intl.messages[`${COMPONENT_INTL_KEY}.Form.ClickHere`]}</Link>,
              }}
            />
          </Flex>
        )}
        <ModalContent>
          {type === IntegrationType.Shopify && <ShopifyIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.WooCommerce && <WooCommerceIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.OpenApi && <OpenApiIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.Entegra && <EntegraIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.Ticimax && <TicimaxIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.TSoft && <TSoftIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.Trendyol && <TrendyolIntegrationForm {...IntegrationFormProps} />}
          {type === IntegrationType.OrderDesk && <OrderDeskIntegrationForm {...IntegrationFormProps} />}
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.Info`]}
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={state.tSoft.step == IntegrationSteps.Configuration && type === IntegrationType.TSoft}
        onClose={handleTsoftFormClose}
        size="3xl"
      >
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Title.${type}`]}</ModalTitle>
          {type === IntegrationType.TSoft && (
            <Box mt="10px">
              <Text color={state.tSoft.step == IntegrationSteps.Integration ? 'palette.blue_link' : ''}>
                1- {intl.messages[`${COMPONENT_INTL_KEY}.Integration`]}
              </Text>{' '}
              <Text color={state.tSoft.step == IntegrationSteps.Configuration ? 'palette.blue_link' : ''} ml="10px">
                2- {intl.messages[`${COMPONENT_INTL_KEY}.Configuration`]}
              </Text>
            </Box>
          )}
        </ModalHeader>

        <ModalContent>
          <TSoftMappingForm {...tsoftMappingFormProps} />
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.Info`]}
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={state.ticimax.step == IntegrationSteps.Configuration && type === IntegrationType.Ticimax}
        onClose={handleTicimaxFormClose}
        size="3xl"
      >
        <ModalHeader>
          <ModalTitle>{intl.messages[`${COMPONENT_INTL_KEY}.Form.Title.${type}`]}</ModalTitle>
          {type === IntegrationType.Ticimax && (
            <Box mt="10px">
              <Text color={state.ticimax.step == IntegrationSteps.Integration ? 'palette.blue_link' : ''}>
                1- {intl.messages[`${COMPONENT_INTL_KEY}.Integration`]}
              </Text>{' '}
              <Text color={state.ticimax.step == IntegrationSteps.Configuration ? 'palette.blue_link' : ''} ml="10px">
                2- {intl.messages[`${COMPONENT_INTL_KEY}.Configuration`]}
              </Text>
            </Box>
          )}
        </ModalHeader>

        <ModalContent>
          <TicimaxMappingForm {...ticimaxMappingFormProps} />
        </ModalContent>
        <ModalFooter borderTop="xs" borderColor="palette.snow_light">
          <Flex>
            <Icon mr="8px" name="far fa-question-circle" fontSize="21px" />
            <Text color="palette.grey" fontSize="13" lineHeight={1.54}>
              {intl.messages[`${COMPONENT_INTL_KEY}.Form.Info`]}
            </Text>
          </Flex>
        </ModalFooter>
      </Modal>

      {state.creationSuccess && (
        <Dialog
          type={DialogTypes.Success}
          message={intl.messages[`${COMPONENT_INTL_KEY}.SuccessDialog`]}
          isOpen={state.creationSuccess}
          onCancel={() => {
            actions.setStateData(IntegrationState.CreationSuccess, false), window.location.reload();
          }}
          onApprove={() => {
            actions.setStateData(IntegrationState.CreationSuccess, false), window.location.reload();
          }}
          text={{
            approve: intl.messages[`Modal.Success.Okay`],
          }}
        />
      )}
    </Box>
  );
};
